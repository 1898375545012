<template>
  <div>
    <Discover />
  </div>
</template>

<script>
import Discover from "./components/DiscoverLogout.vue";
export default {
  name: "",
  components: {
    Discover,
  },
  data() {
    return {};
  },
  mounted() {},
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
</style>
