<template>
  <!-- <div class="discover-Logout">建设中..</div> -->
  <!-- <div class="discover-Logout">coming soon...</div> -->
  <div class="discover-container">
    <!-- <img class="image" :src="require(`@/assets/images/12.png`)" /> -->
    <!-- 导航栏 -->
    <van-nav-bar
      v-show="isshow"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <!-- <van-icon slot="right" name="add-o" @click="$router.push('/picture')" /> -->
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'"
          >Chia sẻ nhận xét</span
        >
      </template>
      <van-icon slot="right" name="add-o" @click="clickPublic" color="#fff" />
    </van-nav-bar>
    <!-- :color="!$isLinkGame ? '#ffd400' : '#fff'" -->

    <!-- 晒单列表 -->
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :success-text="refreshSuccessText"
      loosing-text="Phát hành để làm mới..."
      loading-text="Loading..."
      pulling-text="Thả xuống để làm mới ..."
    >
      <van-list
        :finished="finished"
        finished-text="Không còn bài viết"
        @load="onLoad"
        loading-text="Đang tải..."
      >
        <van-cell v-for="(item, index1) in discoverList" :key="index1">
          <div slot="default" class="list-item">
            <img class="avatar" :src="item.userimg" alt="" />
            <div class="list-content">
              <span class="username">{{ item.username }}</span>
              <span class="text">{{ item.content }}</span>
              <div
                class="img"
                v-for="(picture, i) in item.pictures"
                :key="i + '1'"
              >
                <img :src="picture.url" alt="" @click="clickimg(item)" />
                <van-image-preview
                  v-model="show"
                  :images="images"
                  @change="onChange"
                >
                  <template v-slot:index>{{ i / images.length }}</template>
                </van-image-preview>
              </div>
              <div class="like-box" @click="clickLike(index1, item.id)">
                <i
                  class="iconfont icon-dianzan1 redLike"
                  v-show="item.is_good === 1"
                ></i>
                <i
                  v-show="item.is_good === 0"
                  class="iconfont icon-dianzan"
                ></i>
                <span class="likeNum">{{ item.good }}</span>
              </div>
              <p class="comments" @click="goCommentitem(item)">
                Tổng số bình luận: {{ item.comment }} >
              </p>
              <div
                class="comments-box"
                v-for="(commentItem, index) in item.comment_new"
                :key="index"
                @click="goCommentitem(item)"
              >
                <span class="userName">{{ commentItem.username }}:</span>
                <span class="commentsText">{{ commentItem.content }}</span>
              </div>
              <div class="review" @click="GoComment(index1, item.id)">
                Muốn bình luận
              </div>
            </div>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { commentList, commentSlide, clickGood } from '@/api/comments.js'
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      isshow: true,
      discoverList: [],
      index: 0,
      show: false,
      loading: false,
      finished: false,
      refreshing: false,
      pages: '1',
      refreshSuccessText: '', // 下拉刷新成功时显示的文字
      userinfo: window.localStorage.userInfo
        ? JSON.parse(window.localStorage.userInfo)
        : {},
      images: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getcommentList()
  },
  mounted() {},
  methods: {
    onLoad() {
      this.pages++
      this.getcommentList()
    },
    // 下拉刷新时触发的函数
    async onRefresh() {
      const {
        data: { data },
      } = await commentSlide({ position: '' })
      try {
        // 3、下拉结束之后就将下拉状态关闭
        this.refreshing = false
        // 4、下拉成功时显示提示文字
        this.refreshSuccessText = 'Làm mới thành công'
      } catch (error) {
        // 如果下拉失败，则关闭下拉状态，显示下拉失败的消息
        this.refreshing = false
        this.refreshSuccessText = 'làm mới không thành công'
      }
    },
    // 点击当前点赞图标时触发的函数
    async clickLike(index, id) {
      const {
        data: { ret, msg, data },
      } = await clickGood({ id: id })
      console.log(data)
      console.log(this.discoverList)
      if (ret === 1) {
        this.clickColor = !this.clickColor
        this.discoverList[index].good = data.good
        this.discoverList[index].is_good = data.is_good
      } else {
        this.$toast(msg)
      }
    },

    async getcommentList() {
      this.loading = true
      const {
        data: { ret, msg, data },
      } = await commentList({
        page: this.pages,
      })
      if (ret === 1) {
        this.discoverList.push(...data.lists)
        this.discoverList.forEach((item) => {
          item.pictures.forEach((e) => {
            const list = []
            list.push(e.url)
            e.lists = list
          })
        })
        this.loading = false
        if (this.discoverList.length === data.total) {
          this.finished = true
        }
      } else {
        this.$toast(msg)
      }
    },

    // 点击去评论时触发的函数
    GoComment(index, id) {
      this.num = index
      if (this.num === index && this.userinfo.token) {
        this.$router.push({
          path: '/discoverReview',
          query: { isToken: true, id: id },
        })
      } else {
        this.$router.push({
          path: '/discoverReview',
          query: { isToken: false },
        })
      }
    },

    // 点击发布的按钮时触发
    clickPublic() {
      this.userinfo.token
        ? this.$router.push('/discoverPublish')
        : this.$router.push('/login')
    },

    // 点击评论内容时触发的函数
    goCommentitem(item) {
      this.$router.push({ path: '/commentItem', query: { item: item } })
      window.localStorage.commentItem = JSON.stringify(item)
    },
    onChange(index) {
      this.index = index
    },
    //  预览图片时触发的内容
    clickimg(item) {
      this.show = true
      this.images = item.pictures[0].lists
    },
  },
}
</script>

<style lang="less" scoped>
.image {
  width: 100%;
  margin-top: 100px;
}

.discover-container {
  .van-nav-bar {
    width: 100%;
    height: 100px;
    position: fixed;
  }
  /deep/ .van-nav-bar__content {
    // background: #ff0000;
    .van-nav-bar__title {
      // color: #fff;
    }
    .van-icon {
      font-size: 40px;
      // color: #fff;
    }
  }
  .van-list {
    margin: 100px 0;
    .van-cell {
      margin-top: 15px;
      padding: 0 20px;
      .list-item {
        display: flex;
        justify-content: flex-start;
        .avatar {
          flex: 0.5;
          box-sizing: border-box;
          padding-top: 15px;
          width: 100px;
          height: 114.19px;
        }
        .list-content {
          display: flex;
          flex: 2.5;
          padding-left: 25px;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          .img {
            margin-top: 10px 0 15px 0;
            width: 300px;
            height: 300px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .like-box {
            width: 100%;
            height: 35px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 35px;
            color: #999;
            .iconfont {
              font-size: 35px;
            }
            .redLike {
              color: #fd7bb8;
            }
          }
          .comments {
            color: #aaa;
          }
          .comments-box {
            font-size: 26px;
            .commentsText {
              color: #aaa;
            }
          }
          .review {
            width: 100%;
            font-size: 25px;
            color: #aaa;
            margin-bottom: 15px;
            border-bottom: 1px solid #eee;
          }
        }
      }
    }
  }
}
</style>
